@import "../base/variables";
@import "../base/breakpoints";

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.orangeText {
  font: 400 normal 14px/18px $sans;
  color: tomato;
}

.hr {
  margin: 25px 0;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.referralform {
  max-width: 300px;
}

.textarea {
  border: 2px solid rgba(0, 0, 0, 0.07);
  margin: 25px 0;
  padding: 6px;
  border-radius: 3px;
  background-color: #f7f6f5;
  box-shadow: none;
  font: 400 normal 14px/18px $sans;
  color: rgba(0, 0, 0, 0.5);
  outline: none;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  display: block;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  font: 400 normal 14px/18px $sans;
  color: rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(0, 0, 0, 0.07);
  margin: 25px 0;
  border-radius: 3px;
  background-color: #f7f6f5;
  box-shadow: none;
}

#referral-sources {
  padding-right: 50px;
}

.referralSubmit {
  display: block;
  margin: 0 auto;
  align-items: center;
  background: transparent;
  border: 1px solid rgba(118, 141, 47, 0.4);
  border-radius: 2px;
  box-sizing: border-box;
  color: #647a1c;
  cursor: pointer;
  font-size: 10px;
  font-weight: normal;
  height: 100%;
  letter-spacing: 1px;
  line-height: 140%;
  padding: 6px 12px;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

input::-ms-clear {
  width: 0;
  height: 0;
}

input.input {
  -webkit-appearance: none;
  outline: none;
  border: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font: 400 normal 13px/18px $sans;
  padding: 12px;
  z-index: 50;
  position: relative;
  text-align: left;
  background: #f7f6f5;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  -webkit-transition: background-color 250ms;
  transition: background-color 250ms;
  font: 400 normal 14px/18px $sans;
  height: 60px;
  height: 48px;
  height: 54px;
}

input.input.active,
input.input.error,
input.input.valid,
input.input:focus {
  padding-top: 24px;
  padding-bottom: 6px;
}

input.input.active,
input.input.valid,
input.input:focus {
  color: rgba(0, 0, 0, 0.7);
}

input.input:focus {
  background-color: rgba(0, 0, 0, 0.03);
}

input[type="checkbox"],
input[type="radio"] {
  background: #eaf;
  margin-right: -2px;
  margin-left: 2px;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
  width: 16px;
  height: 16px;
  line-height: 16px;
  content: "";
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  margin: 0 7px 0 -15px;
  vertical-align: middle;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
  color: #fff;
  background-color: #fff;
}

input[type="radio"] + label::before {
  margin-left: -12px;
}

input[type="checkbox"] + label::before {
  font-size: 13px;
  content: "\00a0";
}

input[type="checkbox"]:checked + label::before {
  font-family: ligicons;
  content: "\e900";
  background-color: #3da7e1;
}

input[type="radio"] + label::before {
  font-size: 22px;
  line-height: 22px;
  border-radius: 20px;
  content: "\00a0";
}

input[type="radio"]:checked + label::before {
  content: "•";
  font-family: monospace;
  font-size: 18px;
  line-height: 14px;
  color: $link-blue;
}
/* hides the actual checkbox, so that we only see the label */
input[type="checkbox"].toggle {
  max-height: 0;
  max-width: 0;
  opacity: 0;
}
/* the "container" for our toggle in its default (off) state */
input[type="checkbox"].toggle + label {
  display: block;
  position: relative;
  text-indent: -5000px;
  height: 32px;
  width: 50px;
  border-radius: 32px;
  background: #eee;
  -webkit-transition: 500ms cubic-bezier(0, 0, 0, 1);
}

input[type="checkbox"].toggle:checked + label {
  background: #3da7e1;
}
/* the actual switch and its drop shadow */
input[type="checkbox"].toggle + label::after {
  content: "";
  position: absolute;
  display: block;
  height: 28px;
  width: 28px;
  top: 2px;
  left: 2px;
  border-radius: 28px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: 250ms cubic-bezier(0, 0, 0, 1);
  background: white;
}

input[type="checkbox"].toggle:checked + label::after {
  left: 20px;
}

fieldset {
  border-radius: 2px;
  background: $putty-faint;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
  position: relative;
  margin-bottom: 24px;
  border: none;

  label {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    text-align: left;
    color: rgba(0, 0, 0, 0.4);
    font: 400 normal 14px/30px $sans;
    padding: 12px;
    box-sizing: border-box;
    height: 60px;
    height: 48px;
    height: 54px;
  }

  label.error,
  label.focused,
  label.valid {
    opacity: 1;
    text-transform: uppercase;
    font: 500 normal 11px/14px $sans;
    padding: 8px 12px;
    // font-weight: 500;
  }

  label.error {
    color: #b51c1c;
  }

  label.error::after,
  label.valid::after {
    content: "\e116";
    font-family: ligicons;
    position: absolute;
    right: 12px;
    top: 17px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    font-size: 14px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    background: #fff;
    color: #5eae7b;
    z-index: 50;
  }

  label.error::after {
    content: "\e117";
    color: rgba(181, 28, 28, 1);
  }

  input.input {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }

  input.input:focus {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }

  label.select::before {
    font-size: 22px;
    line-height: 54px;
    font-family: ligicons;
    position: absolute;
    text-transform: none;
    right: 12px;
    top: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.3);
    content: "\e60b";
    z-index: 10;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    outline: none;
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 54px;
    border-radius: 0;
    left: 0;
    background: transparent;
    padding: 12px;
    z-index: 50;
    position: relative;
    text-align: left;
    font: 400 normal 14px/18px $sans;
    color: rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }

  select.active,
  select.error,
  select.valid,
  select:focus {
    padding-top: 18px;
    padding-bottom: 6px;
    opacity: 1;
  }

  select:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.03);
  }

  select.valid {
    color: rgba(0, 0, 0, 0.7);
  }

  a.help {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 14px;
    right: 12px;
    line-height: 14px;
    border-radius: 14px;
    text-align: center;
    font-size: 12px;
    font-family: $sans;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  > label:first-of-type + * {
    border-color: rgba(0, 0, 0, 0);
    // background-color: rgba(0, 0, 0, .3);
  }
}
/* group Nested Inputs */
/* group 3 inputs */
fieldset div {
  box-sizing: border-box;
  height: 48px;
  height: 54px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  // background: rgba(160, 240, 210, .5);
}

fieldset div.inputs-50-50 {
  > label {
    width: 50%;
  }

  > select,
  > input.input {
    border-radius: 0;
    border: none;
    position: absolute;
    left: 0;
    width: 50%;
  }

  > input.input:nth-child(4),
  > select,
  > label:nth-child(3) {
    left: 50%;
  }

  > input.input:nth-child(4),
  > label:nth-child(4) {
    left: 0;
    width: 100%;
    margin-top: 54px;
  }
}

fieldset div.inputs-33-33-33 {
  > label {
    width: 33.33%;
  }

  input.input,
  select {
    border-radius: 0;
    border: none;
    position: absolute;
    left: 0;
    width: 33.33%;
  }

  input.input:nth-child(4),
  label:nth-child(3) {
    left: 33.33%;
  }

  input.input:nth-child(6),
  label:nth-child(5),
  select {
    left: 66.66%;
  }
}

fieldset.address {
  .city-state-zip {
    height: 108px;
    border: none;

    label {
      width: 50%;
    }

    input.input,
    select {
      border-radius: 0;
      position: absolute;
      left: 0;
      width: 50%;
    }

    input.input:nth-child(4),
    label:nth-child(3),
    select {
      left: 50%;
      border: none;
    }

    input.input:nth-child(5),
    label:nth-child(4) {
      left: 0;
      top: 54px;
      width: 100%;
    }

    input.input:nth-child(5) {
    }
  }
}

fieldset.address.no-admins {
  .state-select {
    display: none;
  }
}

fieldset.address.no-postal-code {
  .postal-code {
    display: none;
  }
}
/* group 2 inputs */
fieldset div.inputs-66-33 input.input {
  border-radius: 0;
  border: none;
  position: absolute;
  left: 0;
}

fieldset div.inputs-66-33 input.input:first-of-type,
fieldset div.inputs-66-33 label:first-of-type {
  width: 66.66%;
}

fieldset div.inputs-66-33 input.input:last-of-type,
fieldset div.inputs-66-33 label:last-of-type {
  width: 33.33%;
  left: 66.66%;
}
@-moz-document url-prefix() {
  div.select select {
    padding-left: 9px;
  }
}

#payment_iframe {
  width: 100%;
  height: 200px;
}

.login {
  font-size: 12px;

  #login-btn {
    cursor: pointer;
  }
}

.paymentMethods_label {
  font-size: 14px;
}

.gift-summary {
  font-size: 12px;
}

.gift-summary .summary-divider {
  width: 100%;
  height: 1px;
  background: rgba($putty-medium, 0.4);
  margin: 8px 0
}

.underline {
  text-decoration: underline;
}

#gift-summary-title {
  width: 100%;
  color: $putty-medium;
  text-align: center;
  border-bottom: 1px solid rgba($putty-medium, 0.4);
  line-height: 0.1em;
  margin: 24px 0px;
}

#gift-summary-title span {
  background: #fff;
  padding: 0 20px;
  font-size: 11px;
}

#gift-summary-body {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14.13px;

  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

#gift-summary-body > div {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

.gift-summary-row {
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
}

.covered-fees-container {
  padding-left: 16px;
  gap: 0px
}

.covered-fees-label {
  display: flex;
  align-items: center;
  user-select: none;
}

.covered-fees-tooltip-button {
  padding: 0px 4px;
}

.covered-fees-tooltip {
  margin: 8px 8px 0px 8px;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
}