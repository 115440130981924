@import "../base/variables";
@import "../base/breakpoints";

#modal {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 260px;
  width: 98%;
  height: auto;
  z-index: 2000;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-perspective: 1000;
  perspective: 1000;

  h2 {
    border-radius: 3px 3px 0 0;
    line-height: 54px;
    margin: 0;
    background-color: $putty-faint;
    font: 500 normal 14px/54px $sans;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }
}

#modal-close-btn {
  background: transparent;
  padding: 0;
  border: none;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  font: normal 20px/54px ligicons;
  width: 54px;
  height: 54px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: color 300ms;
  transition: color 300ms;
  cursor: pointer;
}

#modal-close-btn:active {
  color: rgba(0, 0, 0, 0.3);
}

#modal-content {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #fff;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-perspective: 1000;
  perspective: 1000;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
  opacity: 0;
}

#modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(40, 40, 40, 0.8);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
  visibility: hidden;
  opacity: 0;
}

.modal #modal {
  visibility: visible;
}

.modal #modal-overlay {
  opacity: 1;
  visibility: visible;
}

.modal #modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

#share-to {
  text-align: center;

  a {
    display: inline-block;
    width: 72px;
    vertical-align: middle;
    overflow: hidden;

    &::before {
      color: $charcoal;
      font-family: ligicons;
      content: attr(data-icon);
      text-align: center;
      padding: 0 10px;
      font-size: 54px;
    }
  }
}

@media screen and (min-width: 480px) {
  #modal {
    max-width: 320px;
  }
}
