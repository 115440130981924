@import "variables";

// Breakpoints

$breakpoints-map: (
  xl: $break-xl,
  l: $break-l,
  m: $break-m,
  s: $break-s
);

@mixin make-breakpoint-classes($bp) {
  @media screen and (max-width: map-get($breakpoints-map, $bp)) {
    @include block("-#{$bp}");
  }
}

@mixin make-all-breakpoint-classes {
  @include make-breakpoint-classes("l");
  @include make-breakpoint-classes("m");
  @include make-breakpoint-classes("s");
}
