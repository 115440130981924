#resources {
  header {
    padding-right: 15%;
  }
}

nav.tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  margin: 18px 0;
  height: 36px;

  a {
    display: inline-block;
    line-height: 35px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    vertical-align: top;
    padding: 0;
    margin-right: 15px;
    color: $putty-medium;

    &:last-of-type {
      margin-right: 0px;
    }

    &.active {
      color: $charcoal;
      border-bottom: 1px solid $charcoal;
    }
  }
}

.panes {
  display: none;
}
.panes.active {
  display: block;
}

// Messages

.messages {
  li {
    position: relative;
    display: block;
    margin: 0;
    padding-left: 35px;
    padding-bottom: 6px;

    &:last-of-type {
      padding-bottom: 0px;
    }

    span {
      position: absolute;
      top: 3px;
      left: 3px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

// Resources Slideshow

.resources-slideshow {
  figure {
    display: none;
  }

  figure.active {
    display: block;
  }

  nav {
    text-align: center;
    margin-top: 42px;
  }

  nav a {
    text-align: center;
    font-size: 24px;
    display: inline-block;
    padding: 5px;
    font-family: "Lucida Grande";
    color: rgba(50, 40, 40, 0.3);
  }

  nav a.active {
    color: rgba(50, 40, 40, 0.9);
  }
}

@media screen and (max-width: $break-s) {
  #resources header {
    padding-right: 0;
  }
}
