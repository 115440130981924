@import "../base/variables";
@import "../base/breakpoints";

#amounts > #input-wrap {
  display: inline-block;
  line-height: 40px;
  position: relative;
}
#amounts > #input-wrap > div {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
#amounts > #input-wrap > div > #amount-input {
  position: relative;
  width: 100%;
  left: 0;
}

#amounts > #input-wrap #width-machine {
  padding: 0 1rem;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  word-wrap: normal;
}

.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.hidden {
  display: none;
}
.block {
  display: block;
}
.inline-flex {
  display: inline-flex;
}
.sm-inline {
  @media screen and (min-width: $break-s) {
    display: inline;
  }
}
.flex-col {
  flex-direction: column;
}

@media screen and (max-width: $break-s) {
  .date-selection {
    flex-direction: column;
  }
}

#recurring-details {
  border-bottom: 1px solid lightgray;
}

#recurring-btn {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
  color: #1c7fb5;
  cursor: pointer;
  font-family: "Whitney SSm A", "Whitney SSm B", "Lucida Grande", Lucida,
    Verdana, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 40px;
  max-width: 55%;
  padding: 0 15px;
  transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
    color 250ms cubic-bezier(0.5, 0, 0.3, 1),
    border 100ms cubic-bezier(0.5, 0, 0.3, 1);
  -webkit-transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
    color 250ms cubic-bezier(0.5, 0, 0.3, 1),
    border 100ms cubic-bezier(0.5, 0, 0.3, 1);
}

#recurring-btn.active {
  background: #3da7e1;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #fff;
}

#learn-more-btn {
  align-items: center;
  background: none;
  border: none;
  color: rgb(62, 59, 55);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0 0 0 6px;
  padding: 0;
}

#ministry-partner-options {
  margin: 0 auto 12px auto;
  max-width: 300px;
  width: 100%;
}
@media screen and (min-width: $break-s) {
  #ministry-partner-options {
    max-width: 350px;
  }
}

#ministry-partner-details {
  background-color: #f5f5f4;
  border-radius: 2px;
  padding: 1rem;
  margin: 0 auto 12px auto;
  max-width: 390px;
  width: 100%;
}

label[for="ministryPartner"]::before {
  margin: -3px 7px 0 -15px;
}

label[for="recurring_amount"] {
  color: rgba(0, 0, 0, 0.4);
  display: block;
  font-weight: 500;
  font: 400 normal 11px/14px $sans;
  height: 30px;
  padding: 12px 0 0 12px;
  text-transform: uppercase;
  z-index: 1;
}
label[for="recurring_amount"].error {
  color: #b51c1c;
  display: block;
  padding-top: 0;
}

input[name="recurring_amount"].amount {
  background: #f7f6f5;
  border-radius: 2px;
  border: 1px solid #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #333;
  display: inline-block;
  font: 400 normal 14px/18px $sans;
  height: 40px;
  padding: 20px 0 0 25px;
  width: 200px;
  transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
    color 250ms cubic-bezier(0.5, 0, 0.3, 1),
    border 100ms cubic-bezier(0.5, 0, 0.3, 1);
  -webkit-appearance: none;
  -webkit-transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
    color 250ms cubic-bezier(0.5, 0, 0.3, 1),
    border 100ms cubic-bezier(0.5, 0, 0.3, 1);
}

input[name="recurring_amount"].amount.active,
input[name="recurring_amount"].amount:focus {
  border: 1px solid #3da7e1;
  outline: none;
}

input[name="recurring_amount"].amount.active.error,
input[name="recurring_amount"].amount.error:focus {
  border: 1px solid rgba(181, 28, 28, 1);
}

.moneyInput::before {
  color: rgba(0, 0, 0, 0.4);
  content: "$";
  display: block;
  height: 1em;
  left: 0.75em;
  position: absolute;
  top: 1.95em;
  width: 1em;
  z-index: 1;
}
