figure.bible-hardcover-crimson,
figure.book-audiobook-cd,
figure.book-hardcover,
figure.book-paperback,
figure.book-pocket-size,
figure.book-pocketsize,
figure.book-set-paperback,
figure.bundle-book-x-2,
figure.bundle-usb-drive,
figure.conference-series-usb-drive,
figure.magazine,
figure.music-cd,
figure.null-magazine,
figure.series-usb-drive,
figure.teaching-series-study-guide,
figure.teaching-series-usb-drive {
  position: relative;

  img {
    position: relative;
    width: 98%;
    z-index: 1;
    border-radius: 2px;
  }

  &::after {
    content: " ";
    position: absolute;
    background-image: url("/images/book-shadow.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100% 100%;
    top: 0;
    left: 0;
    width: 125%;
    height: 125%;
    z-index: 0;
  }
}

figure.book-audiobook-cd,
figure.music-cd {
  &::after {
    height: 123%;
  }
}

figure.magazine {
  img {
    width: 85%;
    border-radius: 0;
  }

  &::after {
    background-image: url("/images/magazine-shadow.png");
    top: -7%;
    left: -30px;
    width: 142%;
    height: 133.5%;
  }
}

figure.bundle-download,
figure.conference-messages-download,
figure.teaching-series-download {
  img {
    width: 100%;
    box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.2);
  }
}

figure.bundle-usb-drive,
figure.conference-series-usb-drive,
figure.series-usb-drive,
figure.teaching-series-usb-drive {
  img {
    border-radius: 5px;
  }

  &::after {
    background-image: url("/images/usb-shadow.png");
    width: 126%;
    height: 140%;
    border-radius: 5px;
  }
}

figure.conference-messages-cd,
figure.conference-messages-dvd,
figure.null-dvd,
figure.conference-series-cd,
figure.conference-series-dvd,
figure.series-cd,
figure.series-dvd,
figure.teaching-series-cd,
figure.teaching-series-dvd {
  position: relative;
}

figure.conference-messages-cd img,
figure.conference-messages-dvd img,
figure.null-dvd img,
figure.conference-series-cd img,
figure.conference-series-dvd img,
figure.series-cd img,
figure.series-dvd img,
figure.teaching-series-cd img,
figure.teaching-series-dvd img {
  position: relative;
  width: 98%;
  z-index: 1;
}

figure.conference-messages-dvd::after,
figure.conference-series-dvd::after,
figure.null-dvd::after,
figure.series-dvd::after,
figure.teaching-series-dvd::after {
  content: " ";
  position: absolute;
  background: url("/images/dvd-case.png") no-repeat top left;
  background-size: 98% auto;
  top: -1.8%;
  left: 0;
  width: 135%;
  height: 135%;
  z-index: 0;
}

figure.conference-messages-cd::after,
figure.conference-series-cd::after,
figure.series-cd::after,
figure.teaching-series-cd::after {
  content: " ";
  position: absolute;
  background: url("/images/cd-case.png") no-repeat top left;
  background-size: 98% auto;
  top: -2.6%;
  left: 0;
  width: 135%;
  height: 135%;
  z-index: 0;
}

figure.book-epub-ebook,
figure.book-mobi-ebook {
  position: relative;

  img {
    position: relative;
    width: 84%;
    z-index: 1;
    left: 10%;
    margin-top: 10%;
  }

  &::after {
    content: " ";
    position: absolute;
    background: url("/images/ebook-shadow.png") no-repeat top left;
    background-size: 100% auto;
    top: 0;
    left: 0;
    width: 135%;
    height: 150%;
    z-index: 0;
  }
}
