@-webkit-keyframes swell {
  0%,
  100%,
  66% {
    -webkit-transform: scale(0.5);
  }

  33% {
    -webkit-transform: scale(1);
  }
}
@keyframes swell {
  0%,
  100%,
  66% {
    transform: scale(0.5);
  }

  33% {
    transform: scale(1);
  }
}

#loader {
  width: 30px;
  height: 6px;
  position: relative;
  display: inline-block;

  .dos,
  .tres,
  .uno {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    -webkit-animation: swell 900ms infinite ease-in-out both;
    animation: swell 900ms infinite ease-in-out both;
    background: #fff;
    top: 0;
  }

  .uno {
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
    left: 0;
  }

  .dos {
    -webkit-animation-delay: 350ms;
    animation-delay: 350ms;
    left: 10px;
  }

  .tres {
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
    left: 20px;
  }
}
