@import "./base/variables";
@import "./base/breakpoints";

img {
  pointer-events: none;
}

.divider {
  margin-top: 36px;
  padding-top: 36px;
  border-top: 1px solid rgba(30, 30, 30, 0.12);
}

.responsive-video {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.payment iframe {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: none;
}

#deductible-amount,
#gift-amount,
#good-faith-estimate {
  font-weight: 500;
}

#deductible-amount::before,
#gift-amount::before,
#good-faith-estimate::before {
  content: "$";
}

#tax-statement {
  color: $putty-dark;

  em {
    font-weight: $sans-extra-bold;
    font-style: normal;
  }
}

#address-error {
  background-color: #ff6;
}

.grecaptcha-badge {
  opacity: 0;
}


.covered-fees-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}