@import "../base/variables";
@import "../base/breakpoints";

blockquote.quote {
  margin: 24px auto 0;
  background-color: $putty-faint;
  border-radius: 2px;
  text-align: center;

  p {
    font: 400 italic 20px/1.4 $serif;

    &::before {
      content: "";
      display: block;
      width: 72px;
      height: 72px;
      margin: 0 auto 24px;
      border-radius: 90px;
      background-color: $putty-faint;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }

    em {
      font-style: normal;
      color: $putty-medium;
    }
  }

  cite {
    margin-top: 18px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 13px;

    &::before {
      font-size: 60px;
      line-height: 1.2;
      display: block;
      font-family: ligicons;
    }
  }
}

blockquote.burk-parsons {
  p::before {
    background-image: url("/images/signers/burk-parsons.jpg");
  }
  cite::before {
    content: "\e907";
  }
}

blockquote.chris-larson {
  p::before {
    background-image: url("/images/signers/chris-larson.jpg");
  }
  cite::before {
    content: "\e609";
  }
}

blockquote.derek-wh-thomas {
  p::before {
    background-image: url("/images/signers/derek-wh-thomas.jpg");
  }
  cite::before {
    content: "\e908";
  }
}

blockquote.jd-bridges {
  p::before {
    background-image: url("/images/signers/jd-bridges.jpg");
  }
  cite::before {
    content: "\e607";
  }
}

blockquote.john-cobb {
  p::before {
    background-image: url("/images/signers/john-cobb.jpg");
  }
  cite::before {
    content: "\e608";
  }
}

blockquote.john-petersen {
  p::before {
    background-image: url("/images/signers/john-petersen.jpg");
  }
  cite::before {
    content: "\e611";
  }
}

blockquote.rc-sproul {
  p::before {
    background-image: url("/images/signers/rc-sproul.jpg");
  }
  cite::before {
    content: "\e612";
  }
}

blockquote.w-robert-godfrey {
  p::before {
    background-image: url("/images/signers/w-robert-godfrey.jpg");
  }
  cite::before {
    content: "\e90a";
  }
}

blockquote.sinclair-ferguson {
  p::before {
    background-image: url("/images/signers/sinclair-ferguson.jpg");
  }
  cite::before {
    content: "\e904";
  }
}

blockquote.stephen-j-nichols {
  p::before {
    background-image: url("/images/signers/stephen-j-nichols.jpg");
  }
  cite::before {
    content: "\e60f";
  }
}

blockquote.steven-j-lawson {
  p::before {
    background-image: url("/images/signers/steven-j-lawson.jpg");
  }
  cite::before {
    content: "\e60a";
  }
}

blockquote.vesta-sproul {
  p::before {
    background-image: url("/images/signers/vesta-sproul.jpg");
  }
  cite::before {
    content: "\e909";
  }
}
