@import "../base/variables";
@import "../base/breakpoints";

#gift-header {
  position: relative;
  text-align: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.2);
  color: $charcoal;
  height: 90px;
  justify-content: space-between;

  .logo {
    width: 90px;
    height: 90px;
    height: 100%;
    line-height: 90px;
    // color: rgba(255, 255, 255, 0);

    &::before {
      color: #fff;
      font: normal 36px/90px ligicons;
    }
  }
}

.proposition {
  font: 500 12px/90px $sans;
  margin: 0;
  display: block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;

  &::after {
    margin-left: 5px;
    text-indent: 0;
    font: normal 20px ligicons;
    vertical-align: -3px;
    letter-spacing: 0;
  }

  em {
    display: none;
  }
}

.security {
  font-size: 20px;
  font-family: ligicons;
  line-height: 90px;
  width: 90px;
  height: 90px;
  -webkit-transition: color 300ms;
  transition: color 300ms;
  color: #fff;
}

@media screen and (max-width: $break-m) {
  #gift-header {
    height: 72px;

    .logo {
      line-height: 72px;
      width: 72px;
      height: 72px;

      &::before {
        width: 72px;
        line-height: 72px;
        font-size: 30px;
      }
    }
  }

  .proposition {
    line-height: 72px;
  }

  .security {
    width: 72px;
    height: 72px;
    line-height: 72px;
  }
}

@media screen and (max-width: $break-s) {
  #gift-header {
    height: 54px;

    .logo {
      width: 54px;
      height: 54px;
      line-height: 54px;

      &::before {
        width: 54px;
        line-height: 54px;
        font-size: 24px;
      }
    }
  }

  .proposition {
    display: none;
  }

  .security {
    width: 54px;
    height: 54px;
    line-height: 54px;
    font-size: 18px;
  }
}
