@import "../base/variables";
@import "../base/breakpoints";

#expired {
  .logo {
    display: block;
    font: 500 12px/24px $sans;
    color: $charcoal;
    text-transform: uppercase;
    letter-spacing: 3px;

    &::before {
      display: block;
      font: normal 36px/60px ligicons;
      text-transform: none;
      letter-spacing: 0;
    }
  }
}
