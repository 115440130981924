@import "../base/variables";
@import "../base/breakpoints";

#confirmation {
  .logo {
    &::before {
      font: normal 36px/72px ligicons;
      color: $charcoal;
      display: block;
    }
  }

  ul.products li {
    margin-left: 18px;
    list-style-type: square;
    line-height: 1.4;
    margin-bottom: 6px;
  }

  #download-footnote span,
  .ll-icon,
  ul.products li span {
    background-color: $putty-medium;
    background-color: #8cb035;
    border-radius: 2px;
    font-size: 9px;
    font-weight: 600;
    padding: 1px 4px 2px;
    color: #fff;
    margin-left: 3px;
    margin-right: 3px;
    vertical-align: 1px;
  }
}

.order {
  border-radius: 2px;
  margin: 30px 0;
  padding: 30px;
  background-color: $putty-faint;
}

#donationAmountConfirmation div:nth-child(2) {
  margin-top: 30px;
}

#recurring.monthly-partner #partnership {
  display: block;
}

address {
  margin-bottom: 24px;
  margin-left: 18px;
  margin-left: 0;
}

address span.fn,
address span.org,
address span.street-address {
  display: block;
}
