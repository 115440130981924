@import "variables";
@import "breakpoints";

.container {
  width: 90%;
  margin: 0 auto;
  max-width: $break-xl;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.row .row {
  margin-left: -18px;
  margin-right: -18px;
}

.col {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.col-auto {
  flex: 1;
}

$widths: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  33.3333,
  66.6666
);

@each $width in $widths {
  .col-#{floor($width)} {
    @extend .col;
    width: #{$width}#{"%"};
  }
}

@mixin block($bp: "") {
  %col {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 18px;
  }

  @each $width in $widths {
    .col-#{floor($width)}#{$bp} {
      @extend col !optional;
      width: #{$width}#{"%"};
    }
  }
}

@include block;
@include make-breakpoint-classes("l");
@include make-breakpoint-classes("m");

@media screen and (max-width: $break-s) {
  .row .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col {
    width: 100%;
    padding: 9px 0;
  }
}

@include make-breakpoint-classes("s");
