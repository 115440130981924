@import "variables";
@import "breakpoints";

// Display

@mixin block($bp: "") {
  .flex#{$bp} {
    display: flex;
  }
  .block#{$bp} {
    display: block;
  }
  .inline#{$bp} {
    display: inline;
  }
  .inline-block#{$bp} {
    display: inline-block;
  }
  .hide#{$bp} {
    display: none;
  }
  .unhide#{$bp} {
    display: inherit;
  }
}

@include block;
@include make-all-breakpoint-classes;
