@import "variables";
@import "breakpoints";
// Positioning

// @mixin block($bp: '') {
//   .static#{$bp}         { position: static; }
//   .relative#{$bp}       { position: relative; }
//   .absolute#{$bp}       { position: absolute; }
//   .fixed#{$bp}          { position: fixed; }
//   .sticky#{$bp}         { position: -webkit-sticky; position: sticky; }
// }
//
// @include block;
// @include make-all-breakpoint-classes;

// Text align

@mixin block($bp: "") {
  .center#{$bp} {
    text-align: center;
  }
  .left#{$bp} {
    text-align: left;
  }
  .right#{$bp} {
    text-align: right;
  }
}

@include block;
@include make-all-breakpoint-classes;

// Column alignment

@mixin block($bp: "") {
  .cols-left#{$bp} {
    justify-content: flex-start;
  }
  .cols-center#{$bp} {
    justify-content: center;
  }
  .cols-right#{$bp} {
    justify-content: flex-end;
  }
  .cols-top#{$bp} {
    align-items: flex-start;
  }
  .cols-middle#{$bp} {
    align-items: center;
  }
  .cols-bottom#{$bp} {
    align-items: flex-end;
  }
}

@include block;
@include make-all-breakpoint-classes;

// Put flexbox column order here

@mixin block($bp: "") {
  $orders: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
  @each $order in $orders {
    .col-order-#{$order}#{$bp} {
      order: $order;
    }
  }
}

@include block;
@include make-all-breakpoint-classes;
