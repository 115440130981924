@import "../base/variables";
@import "../base/breakpoints";

#expand {
  height: 24px;
  line-height: 24px;
  color: $charcoal;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}

#examples {
  position: fixed;
  z-index: 5000;
  width: auto;
  height: auto;
  bottom: 0;
  bottom: 12px;
  left: 12px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background: $putty-faint;
  border: 1px solid rgba(0, 0, 0, 0.12);

  &:hover {
    background: #333;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .examples-holder {
    width: 100%;
    display: none;
  }

  &:hover .examples-holder {
    display: block;
    display: table;

    padding: 0;
    display: table-cell;
  }

  &:hover #expand {
    display: none;
  }

  .examples-holder > h2 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 30px;
    padding: 12px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    margin-bottom: 0;
  }

  ul {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 18px 30px 30px;

    li {
      margin: 0;
      display: block;
      padding: 12px 0;
      line-height: 1.2;
      color: #fff;

      &:last-child {
        padding-bottom: 0;
      }

      h2 {
        color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
        text-transform: uppercase;
        margin-bottom: 3px;
      }

      h3 {
        font-size: 13px;
        margin-bottom: 0;
        color: #fff;
      }

      a {
        font-size: 11px;
        margin-right: 3px;
      }
    }
  }

  &:hover {
    width: 300px;
    height: 400px;
    overflow: scroll;
  }
}
