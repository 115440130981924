@keyframes third-party-payment-disabled {
    from {
        opacity: 0.75;
    }
    to {
        opacity: 1;
    }
}

#third-party-payment-methods {
    position: relative;
    padding:  16px 24px;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    border: 1px solid #e2e2e2;
    border-radius: 2px;

    &[data-disabled="true"] {
        opacity: 0.5;
        pointer-events: none;
    }

    &[data-disabled-type="apple"] {
        & > .apple-pay-button-container {
            animation: third-party-payment-disabled 0.75s ease-in-out infinite alternate;
        }

        & > .google-pay-button-container {
            display: none;
        }
    }

    &[data-disabled-type="google"] {    
        & > .google-pay-button-container {
            animation: third-party-payment-disabled 0.75s ease-in-out infinite alternate;
        }

        & > .apple-pay-button-container {
            display: none;
        }
    }


    & > .third-party-payment-method-label-container {
        position: absolute;
        top: -9px;
        left: 0;
        right: 0;
        text-align: center;
        display: flex;
        justify-content: center;

        & p {
            background: #ffffff;
            padding: 0px 10px;
            font-size: 11px;
            font-weight: 500;
            color: #000000;
        }
    }
 
   & .apple-pay-button-container,
    .google-pay-button-container {
        flex: 1;
        height: 30px;
        width: 100% !important;

        &:hover {
            opacity: 0.8 !important;
        }

        & button {
            flex:1;
            height: 30px !important;
            width: 100% !important;
            min-height: 30px !important;
            min-width: 0px !important;
            opacity: 1 !important;
            background-color: #000000 !important;
        }
    }

    & .google-pay-button-container {
        & button {
            
            padding: 8.5px !important;
            outline: none !important;
        }

        & > div {
            width: 100% !important;
        }
    }
}

.third-party-payment-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 42px;

    & > div {
        flex: 1;
        height: 1px;
        background-color: #e2e2e2;
    }

    & > p {
        position: absolute;
        font-size: 12px;
        font-weight: 500;
        color: #c2c2c2;
        background-color: #ffffff;
        padding: 0px 15px;
    }
}