@import "../base/variables";
@import "../base/breakpoints";

#footer {
  p {
    color: $putty-dark;

    a {
      color: $putty-dark;
      text-decoration: underline;
    }
  }

  p.contact {
    span span {
      display: inline-block;
      margin: 0 7px;
      width: 1px;
      height: 14px;
      vertical-align: middle;
      background-color: $putty-dark;
      opacity: 0.3;
    }
  }
}

a.ecfa-logo,
.ssl-logo {
  display: inline-block;
  font-size: 1px;
  letter-spacing: -1px;
  color: transparent;
  cursor: pointer;
  line-height: 1;
}

a.ecfa-logo::before,
.ssl-logo::before {
  display: inline-block;
  font-family: ligicons;
  font-size: 40px;
  letter-spacing: normal;
  cursor: pointer;
  color: $putty-medium;
  margin: 0 5px;
}

.ssl-logo::before {
  font-size: 36px;
  content: "\e802";
}

a.ecfa-logo::before {
  content: "\e621";
  margin: 0 5px 0 9px;
}

@media screen and (max-width: $break-m) {
  #footer {
    .contact > span {
      display: block;
    }
  }

  #footer {
    p.contact {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 640px) {
  // #footer {
  //   padding: 54px 0 36px;
  // }

  // a.ecfa-logo::before,
  // .ssl-logo::before {
  //   font-size: 32px;
  // }
  //
  // .ssl-logo::before {
  //   font-size: 30px;
  // }
}
