@import "../base/variables";
@import "../base/breakpoints";

.lm {
  .logo::before {
    content: $lm-mark;
  }

  #gift-header {
    background-image: url("/images/lm_2021.jpg");
  }

  .proposition::after {
    content: $lm-wordmark;
    font-size: 16px;
    vertical-align: 0px;
    margin-left: 7px;
  }

  #login-btn {
    color: $lm-color;
  }

  .paymentMethods {
    input[type="radio"]:checked + label::before {
      color: $lm-color;
    }
  }

  .btn {
    background-color: $lm-color;

    &:active {
      background-color: active-color-state($lm-color);
    }
  }

  .form-layout blockquote footer::before {
    content: "\e612";
  }
}

.lmc {
  .logo::before {
    content: $lmc-mark;
  }

  #gift-header {
    background-image: url("/images/lm.jpg");
  }

  .proposition::after {
    content: $lmc-wordmark;
    font-size: 16px;
    vertical-align: 0px;
    margin-left: 7px;
  }

  #login-btn {
    color: $lmc-color;
  }

  .paymentMethods {
    input[type="radio"]:checked + label::before {
      color: $lmc-color;
    }
  }

  .btn {
    background-color: $lmc-color;

    &:active {
      background-color: active-color-state($lmc-color);
    }
  }

  .form-layout blockquote footer::before {
    content: "\e612";
  }
}

.mp {
  .logo::before,
  #confirmation .logo::before {
    content: $mp-mark;
    font-size: 48px;
  }

  #gift-header {
    background-image: url("/images/mp.jpg");
  }

  .proposition::after {
    content: $mp-wordmark;
  }

  #login-btn {
    color: $mp-color;
  }

  .paymentMethods {
    input[type="radio"]:checked + label::before {
      color: $mp-color;
    }
  }

  .btn {
    background-color: $mp-color;

    &:active {
      background-color: active-color-state($mp-color);
    }
  }
}

.rym {
  .logo::before {
    content: $rym-mark;
  }

  #gift-header {
    background-image: url("/images/rym-nav-bg.jpg");
    background-position: right center;
  }

  .proposition::after {
    content: "";
    width: 100px;
    height: 40px;
    transform: translate(0%, 25%);
    display: inline-block;
    background-image: url("/images/RYM_Wordmark_white.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  #login-btn {
    color: $rym-color;
  }

  .paymentMethods {
    input[type="radio"]:checked + label::before {
      color: $rym-color;
    }
  }

  .btn {
    background-color: $rym-color;

    &:active {
      background-color: active-color-state($rym-color);
    }
  }
}

.rt {
  .logo::before {
    content: $rt-mark;
    font-size: 30px;
  }

  #confirmation h1::before {
    content: $lm-mark;
  }

  #gift-header {
    background-image: url("/images/rt.jpg");
    background-position: left top;
  }

  .proposition::after {
    content: $rt-wordmark;
  }

  #login-btn {
    color: $rt-color;
  }

  .paymentMethods {
    input[type="radio"]:checked + label::before {
      color: $rt-color;
    }
  }

  .btn {
    background-color: $rt-color;

    &:active {
      background-color: active-color-state($rt-color);
    }
  }
}

.rbc {
  .logo::before {
    content: $rbc-mark;
  }

  #gift-header {
    background-image: url("/images/rbc.jpg");
  }

  .proposition::after {
    content: $rbc-wordmark;
    font-size: 12px;
    vertical-align: 0px;
  }

  #login-btn {
    color: $rbc-color;
  }

  .paymentMethods {
    input[type="radio"]:checked + label::before {
      color: $rbc-color;
    }
  }

  .btn {
    background-color: $rbc-color;

    &:active {
      background-color: active-color-state($rbc-color);
    }
  }
}
