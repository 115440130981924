.maintenance-mode {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .maintenance-logo {
    background-color: pink;
    background: url("/images/logo.svg") no-repeat top left;
    background-size: 100% auto;
    width: 56px;
    height: 56px;
    z-index: 0;
  }

  p.ss2 {
    color: #827b73;
  }
}
