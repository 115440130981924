@import "../base/variables";
@import "../base/breakpoints";

.primary-col {
  background-color: #ffffff;
  position: relative;
  padding-top: 72px;
  padding-top: 54px;
  padding-right: 54px;
  padding-bottom: 72px;
  padding-left: 18px;
  width: 62%;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    top: 20px;
    bottom: 20px;
    left: 50%;
    right: 0;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    -moz-border-radius: 10px / 100px;
    border-radius: 10px / 100px;
    border: none;
  }
}

.secondary-col {
  padding-top: 72px;
  padding-top: 54px;
  padding-right: 18px;
  padding-bottom: 72px;
  padding-left: 54px;
  width: 38%;
}

#confirmation .secondary-col {
  padding-top: 132px;
}

.sticky-holder {
  position: sticky;
  position: -webkit-sticky;
  top: 54px;
}

@media screen and (max-width: $break-m) {
  .primary-col {
    width: 100%;
    padding-top: 36px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    &::before {
      display: none;
    }
  }

  .secondary-col {
    width: 100%;
    padding-top: 24px;
    padding-right: 0;
    padding-bottom: 18px;
    padding-left: 0;
  }

  #resources article {
    justify-content: center;
  }

  #confirmation .secondary-col {
    padding-top: 30px;
  }
}

@media screen and (max-width: $break-s) {
  #confirmation .primary-col {
    padding-top: 0;
  }
}
