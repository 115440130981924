@import "../base/variables";
@import "../base/breakpoints";

#pp-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

#pp-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 75%;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f7f6f5;
}

#pp-modal-sub-content {
}

#pp-btns {
  margin: 0 auto;
  width: 75%;
  text-align: center;
  margin-top: 12px;
}

#accept-pp-btn {
  margin-right: 4px;
}

#decline-pp-btn {
  background-color: $putty-medium;
}
