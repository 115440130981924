@import "variables";

// Text Colors

.charcoal {
  color: $charcoal;
}
.putty-dark {
  color: $putty-dark;
}
.putty-medium {
  color: $putty-medium;
}
.putty-light {
  color: $putty-light;
}
.putty-faint {
  color: $putty-faint;
}

// Background Colors

.bg-putty-dark {
  background-color: $putty-dark;
}
.bg-putty-medium {
  background-color: $putty-medium;
}
.bg-putty-light {
  background-color: $putty-light;
}
.bg-putty-faint {
  background-color: $putty-faint;
}
