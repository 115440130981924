@import "../base/variables";
@import "../base/breakpoints";

.countdown-holder {
  text-align: center;
  margin: 12px auto;

  h2 {
    display: inline-block;
    width: auto;
    margin-right: 2px;
    margin-bottom: 0;
    padding: 0 15px;
    vertical-align: top;
    text-align: left;
    letter-spacing: 1px;
    text-transform: uppercase;
    font: 500 12px/42px $sans;
    border-radius: 2px;
    background-color: $putty-light;
    color: $charcoal;
  }
}

.counter {
  display: inline-block;
  vertical-align: top;

  span {
    display: inline-block;
    text-align: center;
    border-radius: 2px;
    margin: 0 2px;
    font-size: 20px;
    font-weight: 300;
    background-color: $putty-light;
    color: $charcoal;
    vertical-align: top;
    padding: 0 10px;
    line-height: 42px;

    &::after {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 9px;
      line-height: 1;
      margin-left: 4px;
      font-weight: 500;
    }

    &.days::after {
      content: "DAYS";
    }
    &.hours::after {
      content: "HRS";
    }
    &.minutes::after {
      content: "MINS";
    }
    &.seconds::after {
      content: "SECS";
    }
    &.day::after {
      content: "DAY \00a0";
    }
    &.hour::after {
      content: "HR \00a0";
    }
    &.minute::after {
      content: "MIN \00a0";
    }
    &.second::after {
      content: "SEC \00a0";
    }
  }
}

@media screen and (max-width: $break-m) {
  .countdown-holder h2 {
    width: 60px;
    padding: 5px 5px 3px 7px;
    line-height: 17px;
    letter-spacing: 0;
    text-transform: none;
  }

  .counter span {
    line-height: 1;
    padding: 5px 7px;

    &::after {
      margin-top: 3px;
      margin-left: 0;
      display: block;
    }
  }
}

@media screen and (max-width: $break-s) {
  .countdown-holder h2 {
    display: none;
  }
}
