@import "variables";

$increment: 6;

@function get-spacing-size($i, $offset) {
  @if $i == 0 {
    @return 0;
  } @else {
    @return if(
      ($i - $offset) * $increment >= $increment,
      #{(($i - $offset) * $increment)}px,
      #{$increment}px
    );
  }
}

@mixin generate-spacing-classes($offset: 0) {
  @for $i from 0 through 12 {
    $size: get-spacing-size($i, $offset);
    .pt-#{$i} {
      padding-top: $size;
    }
    .pr-#{$i} {
      padding-right: $size;
    }
    .pb-#{$i} {
      padding-bottom: $size;
    }
    .pl-#{$i} {
      padding-left: $size;
    }
    .pa-#{$i} {
      padding: $size;
    }
    .ph-#{$i} {
      padding-left: $size;
      padding-right: $size;
    }
    .pv-#{$i} {
      padding-top: $size;
      padding-bottom: $size;
    }

    .mt-#{$i} {
      margin-top: $size;
    }
    .mr-#{$i} {
      margin-right: $size;
    }
    .mb-#{$i} {
      margin-bottom: $size;
    }
    .ml-#{$i} {
      margin-left: $size;
    }
    .ma-#{$i} {
      margin: $size;
    }
    .mh-#{$i} {
      margin-left: $size;
      margin-right: $size;
    }
    .mv-#{$i} {
      margin-top: $size;
      margin-bottom: $size;
    }
  }
}

@include generate-spacing-classes(0);

@media screen and (max-width: $break-s) {
  @include generate-spacing-classes(1);
}
