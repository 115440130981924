@import "../base/variables";
@import "../base/breakpoints";

#amounts {
  text-align: center;

  a {
    display: inline-block;
    font-weight: 400;
    font-family: $sans;
    line-height: 40px;
    padding: 0 14px;
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
      color 250ms cubic-bezier(0.5, 0, 0.3, 1),
      border 100ms cubic-bezier(0.5, 0, 0.3, 1);
    -webkit-transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
      color 250ms cubic-bezier(0.5, 0, 0.3, 1),
      border 100ms cubic-bezier(0.5, 0, 0.3, 1);

    &::before {
      content: "$";
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  a.active,
  a.active:active {
    background: #1c7fb5;
    background: #3da7e1;
    color: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  a.active:active {
    background: #1c7fb5;
  }

  a:active {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    color: #06546e;
  }

  label {
    display: block;
    text-align: center;
    padding: 12px;
    color: rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    font: 400 normal 11px/14px $sans;
    font-weight: 500;
  }

  label.error {
    color: #1c7fb5;
    color: #b51c1c;
    display: block;
    padding-top: 0;
  }

  input.amount {
    -webkit-appearance: none;
    display: inline-block;
    width: 52px;
    font: 400 normal 14px/18px $sans;
    padding: 0 0 0 12px;
    height: 40px;
    color: #333;
    background: #f7f6f5;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    text-align: left;
    border: 1px solid #fff;
    transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
      color 250ms cubic-bezier(0.5, 0, 0.3, 1),
      border 100ms cubic-bezier(0.5, 0, 0.3, 1);
    -webkit-transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1),
      color 250ms cubic-bezier(0.5, 0, 0.3, 1),
      border 100ms cubic-bezier(0.5, 0, 0.3, 1);
  }

  input.amount.active,
  input.amount:focus {
    outline: none;
    // background: rgba(0,0,0,.07);
    border: 1px solid #3da7e1;
  }

  input.amount.active.error,
  input.amount.error:focus {
    border: 1px solid rgba(181, 28, 28, 1);
  }
}

.offer-value-proposition {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  > img {
    width: 100%;
    display: block;
    margin: 48px auto;
  }

  > img.digital {
    width: 60%;
    margin-bottom: 36px;
  }

  img {
    width: 100%;
    display: block;
  }

  img.digital {
    width: 60% !important;
  }

  .image-object-wrapper {
    margin: 48px auto !important;
  }

  .image-object-wrapper.digital-wrapper {
    margin-bottom: 36px !important;
  }
}

.image-holder {
  > img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}

#pick-x-of-y-list {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 30px 0 0;

  li {
    vertical-align: top;
    display: block;
    box-sizing: border-box;
    position: relative;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    clear: both;
  }

  label {
    vertical-align: top;
    display: block;
    box-sizing: border-box;
    padding: 24px 12px;
    position: relative;
    text-align: left;
    margin: 0;
    padding-left: 50px;
    clear: both;
  }

  strong {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
  }

  span {
    display: block;
    font-size: 13px;
  }

  img {
    margin: 0 0 6px;
    width: 65px;
    float: left;
    display: inline-block;
  }

  input[type="checkbox"],
  input[type="checkbox"] + label::before,
  input[type="radio"],
  input[type="radio"] + label::before {
    margin: 0;
    position: absolute;
    top: 28px;
    left: 30px;
  }

  p {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.format {
  span {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
    padding: 0 7px 0 8px;
    border-radius: 2px;
    color: $putty-medium;
    font-weight: 500;
    background-color: $putty-faint;
  }

  em {
    color: $charcoal;
  }
}

span.format {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-style: italic;
  color: $putty-medium;
  font-size: 12px;
  vertical-align: 3px;
  padding: 0;
  margin: 0;

  em {
    font-style: normal;
  }
}
