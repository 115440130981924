@font-face {
  font-family: "ligicons";
  src: url("/typefaces/ligicons/ligicons.woff2") format("woff2");
  src: url("/typefaces/ligicons/ligicons.ttf") format("truetype");
  src: url("/typefaces/ligicons/ligicons.woff") format("woff");
  src: url("/typefaces/ligicons/ligicons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import "./shame.scss";

@import "./base/breakpoints.scss";
@import "./base/colors.scss";
@import "./base/display.scss";
@import "./base/grid.scss";
@import "./base/positioning.scss";
@import "./base/reset.scss";
@import "./base/spacing.scss";
@import "./base/transitions.scss";
@import "./base/typography.scss";
@import "./base/variables.scss";

@import "./components/brands.scss";
@import "./components/buttons.scss";
@import "./components/counter.scss";
@import "./components/examples.scss";
@import "./components/footer.scss";
@import "./components/forms.scss";
@import "./components/loader.scss";
@import "./components/share.scss";

@import "./gift/appeal.scss";
@import "./gift/expired.scss";
@import "./gift/header.scss";
@import "./gift/offer-resources-shadows.scss";
@import "./gift/offer-resources.scss";
@import "./gift/offer.scss";
@import "./gift/privacy-policy.scss";
@import "./gift/recurring-form.scss";
@import "./gift/maintenance-mode.scss";
@import "./gift/third-party-payments.scss";

@import "./layouts/confirmation.scss";
@import "./layouts/two-column.scss";
