@import "variables";

body {
  font-style: normal;
  font-size: 15px;
  font-family: $sans;
  font-weight: $sans-normal;
  line-height: 1.6;
  text-align: left;
  text-rendering: optimizeSpeed;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  color: $charcoal;
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

// Title Typesettings

.t1 {
  font-size: 42px;
  font-size: 36px;
  font-family: $sans;
  font-weight: $sans-normal;
  line-height: 1.2;
}

.t2 {
  font-family: $sans;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: $sans-extra-bold;
}

.t3 {
  font-size: $sans-size-3;
  line-height: 36px;
}

.t4 {
  font-size: $sans-size-4;
  line-height: 30px;
}

.t5 {
  font-size: $sans-size-5;
  line-height: 24px;
}

// Sans Typesettings

.ss2 {
  font-size: 16px;
}
.ss3 {
  font-size: 13px;
}
.ss4 {
  font-size: 12px;
}
.ss5 {
  font-size: 11px;
}

// Serif Typesettings

.s1,
.s2 {
  font-family: $serif;
  font-weight: $serif-normal;
}

.s1 {
  font-size: 48px;
  line-height: 1.1;
}

.s2 {
  font-size: 36px;
  line-height: 1.1;
}

ol {
  list-style: decimal;
}
ul {
  list-style: square;
}
li {
  margin-left: 18px;
}

.square-bullets {
  list-style-type: square;
}
.no-bullets {
  list-style-type: none;
}
.no-bullets li {
  margin-left: 0;
}

a {
  text-decoration: none;
  color: $link-blue;
}

a:focus {
  outline: 1px dotted invert;
}

strong {
  font-weight: $sans-bold;
}
em {
  font-style: italic;
}
sup {
  font-size: 10px;
  vertical-align: top;
}

@media screen and (max-width: $break-l) {
}

@media screen and (max-width: $break-m) {
}

@media screen and (max-width: $break-s) {
}
