@import "../base/variables";
@import "../base/breakpoints";

[data-icon]::before {
  font-family: ligicons;
  content: attr(data-icon);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "ligicons";
  src: url("/typefaces/ligicons/ligicons.woff2") format("woff2"),
    url("/typefaces/ligicons/ligicons.ttf") format("truetype"),
    url("/typefaces/ligicons/ligicons.woff") format("woff"),
    url("/typefaces/ligicons/ligicons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.btn {
  border: none;
  -webkit-appearance: none;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-weight: 500;
  padding: 0 15px;
  font-size: 13px;
  line-height: 42px;
  cursor: pointer;
  background-color: $button-color;
  letter-spacing: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font: 500 13px/42px $sans;
  transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1);
  -webkit-transition: background-color 200ms cubic-bezier(0.5, 0, 0.3, 1);
  margin-left: 0;
  margin-right: 0;
}

.btn #loader {
  margin-left: 7px;
  vertical-align: middle;
}

.btn.small {
  letter-spacing: 2px;
  line-height: 30px;
}

.btn:enabled:active {
  background-color: #3da7e1;
}

.btn.arrow {
  padding-right: 7px;
}

.btn.arrow::after {
  font-family: ligicons;
  content: "\e60c";
  margin-left: 3px;
  padding: 0;
  line-height: 38px;
  vertical-align: -4px;
  font-size: 18px;
}

#submit-btn:disabled {
  background-color: $putty-medium;
}

#submit-btn {
  width: 100%;
}
